import React, { useState, useRef, useEffect } from "react";
import { navigate } from "gatsby";
import parse from "html-react-parser";
import PageTitle from "../components/core/PageTitle";
import QnA from "../models/QnA";
import { RouteComponentProps } from "@reach/router";
import Layout from "../components/core/Layout";
import MakerOSSection from "../components/ads/MakerOSSection";
import NewsletterRow from "../components/newsletter/NewsletterRow";

// Define unique categories from the FAQ data
const CATEGORIES = {
  GETTING_STARTED: "Getting Started",
  CORE_CONCEPTS: "Core Concepts",
  MIGRATION_INTEROP: "Migration & Interop",
  UI_COMPONENTS: "UI Components",
  STATE_ARCHITECTURE: "State & Architecture",
  PERFORMANCE_TESTING: "Performance & Testing",
  PLATFORM_INTEGRATION: "Platform Integration",
  TOOLING_DEVELOPMENT: "Tooling & Development",
} as const;

function parsedQuestion(question: string | null | undefined): string {
  const questionPattern = /^(.+?)(?:\s*<.*)?$/;
  if (question && question !== null) {
    const match = question.match(questionPattern);
    if (match && match[1]) {
      return match[1];
    }
  }
  return "";
}

function parsedQuestionWithUnderscores(
  question: string | null | undefined
): string {
  if (question && question !== null) {
    const normalizedQuestion = parsedQuestion(question)
      .replace(/\?/g, "")
      .replace(/ /g, "-");
    return normalizedQuestion;
  }
  return "";
}

interface FAQPageComponentProps extends RouteComponentProps {
  pageContext: {
    qnaArray: Array<{
      question: string;
      answer: string;
      categories: string[];
    }>;
    lastUpdateDate: string;
    currentQnA?: QnA;
    featuredJobs: any[];
  };
}

export default function FAQPageComponent(props: FAQPageComponentProps) {
  const ref = useRef<HTMLDivElement>(null);
  const executeScroll = () => ref.current?.scrollIntoView();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [filteredQuestions, setFilteredQuestions] = useState(
    props.pageContext.qnaArray
  );

  // Filter questions based on search query and selected category
  useEffect(() => {
    let filtered = props.pageContext.qnaArray;

    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (qna) =>
          parsedQuestion(qna.question).toLowerCase().includes(lowerCaseQuery) ||
          qna.answer.toLowerCase().includes(lowerCaseQuery)
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter((qna) => {
        return qna.categories.includes(selectedCategory);
      });
    }

    setFilteredQuestions(filtered);
  }, [searchQuery, selectedCategory, props.pageContext.qnaArray]);

  const title =
    props.pageContext.currentQnA !== null &&
    props.pageContext.currentQnA?.question.length !== 0
      ? parsedQuestion(props.pageContext.currentQnA?.question)
      : "Frequently Asked Questions about Jetpack Compose | JetpackCompose.app by Vinay Gaba";
  const description =
    props.pageContext.currentQnA !== null &&
    props.pageContext.currentQnA?.answer.length !== 0
      ? props.pageContext.currentQnA?.answer
      : "Find answers to frequently asked questions about Jetpack Compose! Jetpack Compose is the best way to build for Android.";
  const pageSlug =
    props.pageContext.currentQnA !== null &&
    props.pageContext.currentQnA?.question.length !== 0
      ? `/${parsedQuestionWithUnderscores(
          props.pageContext.currentQnA?.question
        )}`
      : "/faq";

  // https://developers.google.com/search/docs/advanced/structured-data/faqpage
  const structuredDataMainEntity = props.pageContext.qnaArray
    .filter((faq) => {
      const question = parsedQuestion(faq.question);
      return (
        question &&
        question.trim() !== "" &&
        faq.answer &&
        faq.answer.trim() !== ""
      );
    })
    .map((faq) => {
      return {
        "@type": "Question",
        name: parsedQuestion(faq.question),
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer,
        },
      };
    });

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: structuredDataMainEntity,
  };

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  const currentQuestion = parsedQuestion(
    props.pageContext.currentQnA?.question
  );

  return (
    <Layout
      maxWidth="md"
      pageTitle={title}
      pageDescription={description}
      pageSlug={pageSlug}
      pageImageUrl="/faq_poster.png"
      seoImageUrl="/faq_poster.png"
      structuredData={structuredData}
    >
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <PageTitle
          header="Frequently Asked Questions"
          subheader="Find answers to frequently asked questions about Jetpack Compose!"
        />

        {/* Last updated info */}
        <div className="flex items-center text-gray-600 mb-8">
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span className="text-sm">Last updated: March 2025</span>
        </div>

        {/* Search and filter section */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Search questions or answers..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <svg
              className="absolute right-3 top-3 w-5 h-5 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <div className="mt-4">
            <h3 className="text-sm font-semibold text-gray-700 mb-2">
              Filter by category:
            </h3>
            <div className="flex flex-wrap gap-2">
              {Object.values(CATEGORIES).map((category) => (
                <button
                  key={category}
                  onClick={() => handleCategoryClick(category)}
                  className={`px-3 py-1 rounded-full text-sm font-medium transition-colors
                    ${
                      selectedCategory === category
                        ? "bg-blue-100 text-blue-800"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                    }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Results count */}
        <div className="text-sm text-gray-600 mb-4">
          Showing {filteredQuestions.length} of{" "}
          {props.pageContext.qnaArray.length} questions
          {selectedCategory && ` in ${selectedCategory}`}
          {searchQuery && ` matching "${searchQuery}"`}
        </div>

        {/* FAQ Accordion */}
        <div className="space-y-4" ref={ref}>
          {filteredQuestions.length === 0 ? (
            <div className="text-center py-12 bg-gray-50 rounded-lg">
              <svg
                className="mx-auto h-12 w-12 text-gray-400 mb-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                No matching questions found
              </h3>
              <p className="text-gray-600 mb-4">
                Try adjusting your search terms or clearing filters
              </p>
              <button
                onClick={() => {
                  setSearchQuery("");
                  setSelectedCategory(null);
                }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear all filters
              </button>
            </div>
          ) : (
            filteredQuestions.map((qna, index) => {
              const parseQuestionUrl = parsedQuestionWithUnderscores(
                qna.question
              );
              const parseQuestion = parsedQuestion(qna.question);
              const isExpanded = parseQuestion === currentQuestion;

              return (
                <div
                  key={index}
                  className="border border-gray-200 rounded-lg overflow-hidden"
                >
                  <button
                    className="w-full text-left px-6 py-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-inset"
                    onClick={() => {
                      if (isExpanded) {
                        navigate("/faq");
                      } else {
                        navigate("/" + parseQuestionUrl);
                      }
                    }}
                  >
                    <div className="flex justify-between items-start">
                      <div className="pr-6">
                        <h3 className="text-lg font-semibold text-gray-900 mb-1">
                          {parse(qna.question)}
                        </h3>
                        <div className="flex flex-wrap gap-2">
                          {qna.categories.map((category) => (
                            <span
                              key={category}
                              className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
                            >
                              {category}
                            </span>
                          ))}
                        </div>
                      </div>
                      <svg
                        className={`w-6 h-6 text-gray-400 transform transition-transform duration-200 ${
                          isExpanded ? "rotate-180" : ""
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </button>
                  {isExpanded && (
                    <div className="px-6 py-4 border-t border-gray-200 bg-gray-50">
                      <div className="prose max-w-none">
                        {parse(qna.answer)}
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* <MakerOSSection /> */}
        <NewsletterRow />
      </div>
    </Layout>
  );
}
